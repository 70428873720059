import React from 'react'
import SampleVideo from './../../images/sample.mp4';
function weWork() {
    return (
        <div className="section-two section-home d-none d-md-block">
            <div className="container-fluid">
                <div className="row">
                    <video width="100%" height="auto" autoPlay loop muted>
                        <source src={SampleVideo} type="video/mp4" />
                    </video>
                </div>
            </div>

        </div>
    )
}

export default weWork;