import axios from "../../config";
export const SET_POSTALCODE_LIST = "SET_POSTALCODE_LIST";
export const CLEAR_POSTALCODE_LIST = "CLEAR_POSTALCODE_LIST";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const setUserdetails = (payload: any) => ({
  type: SET_USER_DETAILS,
  payload,
});
export const clearUserdetails = () => ({
  type: CLEAR_USER_DETAILS
});
export const getUserDetails = (payload: any) => (dispatch: any) => {
  if (payload) {
    const data = JSON.stringify(payload);
    localStorage.setItem("userDetails", data);
    dispatch(setUserdetails(payload));
  }
};
export const setPostalCodeList = (payload: any) => ({
  type: SET_POSTALCODE_LIST,
  payload,
});
export const fetchPostalCodes = (value: string) => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  try {
    const bodyFormData = new FormData();
    // bodyFormData.append('keyword', value);
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "get",
      url: `${base_url}/api/get_postal_codes?keyword=${value}`,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        "Access-Control-Allow-Origin": "*"
      },
    });
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);

    if (response) {
      // setPostalCodeList(response.data.data[0])
      // localStorage.setItem("postalcode",response.data.postalCodeList[0]?.postcode)
        if(response.data.Response.response_code === "1"){
        dispatch(setPostalCodeList(response.data.data[0]));
      }else{
        return false;
      } 
    }
  } catch (error) {
    
  }
  // axios
  //   .post(apiBase + "/auth/logout/", null, tokenConfig(getState))
  //   .then(res => {
  //     dispatch({
  //       type: LOGOUT_SUCCESS
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch(returnErrors(err.response.data, err.response.status));
  //   });
};

export const readNotification = (id: number) => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/notification_mark_as_read`,
      headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
    })
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);


    if (response) {
      if (response.data.Response) {
        // dispatch(setNotificationList(response.data.Response))
      }
    }
  } catch (error) {
    
  }
}

export const deleteProfile = () => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/delete_user_profile`,
      headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
    })
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);


    if (response) {
      if (response.data.Response) {
        // dispatch(setNotificationList(response.data.Response))
      }
    }
  } catch (error) {
    
  }
}
